import { AiOutlineCheckCircle } from "react-icons/ai";
import { useTranslation } from '@/components/useTranslation';

const style = {
    wrapper: `mx-auto max-w-7xl px-8 py-12 lg:py-16`,
    grid: `grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8 place-content-center items-center`,
    image: `relative overflow-hidden lg:order-last`,
    item: `lg:py-24 w-full mx-auto max-w-md`,
    list: `flex flex-col space-y-6 mt-6`,
    listItem: `max-w-md flex gap-2 items-start`,
    icon: `text-blue-700 text-2xl`,
    text: `!text-md !tracking-normal`,
}


    const Content1 = () => {
    const t = useTranslation();
    
    const contentInner = [
        { text: t.home_step1_list_item1, },
        { text: t.home_step1_list_item2, },
        { text: t.home_step1_list_item3, }
    ]
    
    return (
        <section className='echte-nutzerbewertungen'>
            <div className={style.wrapper}>
                <div className={style.grid}>
                    <div className={style.image}>
                        <video autoPlay loop muted playsInline style={{
                            transform: 'scale(1.02)',
                        }}>
                            <source src="/video/only-comments1.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className={style.item}>
                        <h2>{t.home_step1_title}</h2>
                        <p className='mt-6'>{t.home_step1_description}</p>
                        <ul className={style.list}>
                            {contentInner.map((item, index) => {
                                return (
                                    <li className={style.listItem} key={item.text}>
                                        <div> <AiOutlineCheckCircle className={style.icon} /></div>
                                        <p className={style.text}>{item.text}</p>
                                    </li>
                                )
                            }
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Content1;