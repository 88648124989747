import React from "react";
import Link from "next/link";
import { useTranslation } from '../useTranslation';
import { useState, useContext } from "react";
import { MyContext } from "@/pages/_app"; 

const style = {
  itemInner: `preise-item-inner relative mx-auto hover:cursor-pointer group hover:bg-gradient-to-l hover:from-blue-900 hover:to-blue-600 hover:text-white transition-all md:w-full py-10 px-8 xl:px-14 max-w-md border rounded-3xl flex flex-col text-center items-center justify-start gap-4`,
  
  itemInnerActive: `bg-gradient-to-l from-blue-900 to-blue-600 text-white lg:scale-105`,
  itemInnerDeactive: `bg-white`,
  itemTitle: `font-bold uppercase`,
  itemWrapper: `flex items-start font-bold text-blue-700  preise-item-inner-price group-hover:text-white`,
  itemWrapperActive: `!text-white`,
  itemWrapperDeactive: `text-blue-700`,
  itemPrice: `text-4xl xl:text-6xl preise-item-inner-price-value`,
  itemPriceCurreny: `text-3xl preise-item-inner-price-currency`,

  itemProBewertungWrapper: `flex items-center justify-center preise-item-inner-pro-bewertung pt-4`,
  itemProBewertungWrapper2: `flex flex-col items-center justify-center preise-item-inner-pro-bewertung pt-4`,
  itemProBewertung: `preise-item-inner-pro-bewertung-value group-hover:text-white`,
  itemProBewertungActive: `!text-white`,
  itemProBewertungDeactive: `text-black`,

  itemTotalPrice: `preise-item-inner-total relative -top-2 group-hover:text-white`,
  itemTotalPriceActive: `!text-white`,
  itemTotalPriceDeactive: `text-black`,
  itemPriceButtonWrapper: `pt-4 pb-10 preise-item-inner-button`,
  itemPriceButton: `rounded-full font-medium py-3.5 px-6 sm:px-12  group-hover:bg-white group-hover:text-blue-700 text-sm group-hover:border-blue-700`,
  itemPriceButtonActive: `!text-blue-700 bg-white`,
  itemPriceButtonDeactive: `text-white bg-blue-700`,
  itemFeaturesList: `space-y-3 min-h-[210px] text-sm`,
  itemFeaturesListItem: `group-hover:text-white text-xs sm:text-sm active:text-white text-left`,
  itemFeaturesListItemActive: `!text-white`,
  itemFeaturesListItemDeactive: `text-black`,
}

 function priceCard({title, price, proBewertung, features, active, loschungActive, loschungQuantity, value}) {
  const t = useTranslation();

  const { selectedValue, setSelectedValue } = useContext(MyContext);


  return (
      <div className="pt-12 pb-20 preise-item">
        <div
          className={`
              ${style.itemInner}
              ${active ? style.itemInnerActive : style.itemInnerDeactive}
            `}
        >
          <h4 className={style.itemTitle}>{title}</h4>
          <div className={`
              ${style.itemWrapper}
              ${active ? style.itemWrapperActive : style.itemWrapperDeactive}
          `
          }>
            <span className={style.itemPrice}> {price}</span>
            <span className={style.itemPriceCurreny}>€</span>
          </div>
            {loschungActive === true ? (
                <>
                    <div className={style.itemProBewertungWrapper2}>
                        <p className={` ${style.itemTotalPrice} ${active ? style.itemTotalPriceActive : style.itemTotalPriceDeactive}`} dangerouslySetInnerHTML={{__html: t.loschungData}}></p>
                        <p className={`${style.itemProBewertung} ${active ? style.itemProBewertungActive : style.itemProBewertungDeactive }`}>
                            {loschungQuantity}
                            <strong id="pro-bewertung"> {proBewertung}</strong> {t.reviews}
                        </p>
                    </div>
                </>
            ) : (
                <>
                    <div className={style.itemProBewertungWrapper}>
                        <p className={`${style.itemProBewertung} ${active ? style.itemProBewertungActive : style.itemProBewertungDeactive }`}>
                            <strong id="pro-bewertung">{proBewertung}</strong> {t.reviews}
                        </p>
                    </div>
                    <p className={`${style.itemTotalPrice} ${active ? style.itemTotalPriceActive : style.itemTotalPriceDeactive}`}>{t.garantie}</p>
                </>
            )}

          <div className={style.itemPriceButtonWrapper}>
            <a
                onClick={() => {
                  setSelectedValue(value);
              }}
              href={'#kontakt'}
              className={`${style.itemPriceButton}
                ${active ? style.itemPriceButtonActive : style.itemPriceButtonDeactive }`}>
              {t.button}
            </a>
          </div>
              <ul className={style.itemFeaturesList}>
                {features.map((feature, index) => {
                  return <div className={`${style.itemFeaturesListItem} ${active ? style.itemFeaturesListItemActive : style.itemFeaturesListItemDeactive}`} key={index} dangerouslySetInnerHTML={{__html: feature}}></div>;
                })}
              </ul>

        </div>
      </div>
  );
}


export default priceCard;
