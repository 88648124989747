import Head from 'next/head'
import Hero from '@/components/home/hero'
import RatedCustomers from "@/components/home/rated-customers";
import Content1 from "@/components/home/content/content-1";
import Price from "@/components/price/price";
import Content2 from "@/components/home/content/content-2";
import Services from "@/components/static/services";
import Platform from "@/components/home/platform";
import React  from "react";
import { useTranslation } from "@/components/useTranslation";


export default function Home() {
 

  const t = useTranslation();

  return (
   <>
 
      <Head>
        <title> Avis123 </title>
      </Head>
      <Hero />
        <div className="" style={{
          backgroundImage: "url('/images/global/background/gradient2.png')",
          backgroundRepeat: "repeat",
          backgroundSize: "100%",
        }}>
        <RatedCustomers />
        <Content1/>
        <Price/>
        <Content2/>
        <Services />
        <Platform />
      </div>
      </>
      
  )
}





