import Image from "next/image";
import { useState, useEffect,useRef,useContext,React } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Tab } from "@headlessui/react";
import PriceCard from "./price-card";
import { useTranslation } from "@/components/useTranslation";
import { i18n } from "../../../next.config";
import { MyContext } from "@/pages/_app"; 

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


const profit=[
  "0",
  "0",
  "0",
  "0",
  "0",
  "60",
  "70",
  "80",
  "90",
  "100",
  "220",
  "240",
  "260",
  "280",
  "300",
  "320",
  "340",
  "360",
  "380",
  "400",
  "630",
  "660",
  "690",
  "720",
  "750",
  "780",
  "810",
  "840",
  "870",
  "900",
]



const Price = () => {
  
  const [packages, setPackages] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("Google");
  const [isMobile, setIsMobile] = useState(false);
  const t = useTranslation();
  const [price, SetPrice]=useState(200)
  const [proBewertung, SetproBewertung]=useState(1)
  const [totalProfit, SettotalProfit]=useState(0)
  const firstPrice=200
  const secondPrice=190
  const thirdPrice=180
  const fourthPrice=170
  const MobileThreshold = 900; 
  const [perPaket, SetperPaket]=useState(0)


  const { globalValue, setGlobalValue } = useContext(MyContext);
  const { isSelected, setIsSelected } = useContext(MyContext);
  const { priceLöschen, setpriceLöschen } = useContext(MyContext);


  const handleChangeGlobalValue = (BwtNumber) => {
    setGlobalValue(BwtNumber);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MobileThreshold);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);



  const [value, setValue] = useState(0);
  const prevValueRef = useRef(1);

      
  const handleChange = (event) => {
      const currentValue = event.target.value
      const movePositionperStep=event.target.clientWidth/30
      let positionChangeInPixels =null
      if(isMobile){
        positionChangeInPixels= (currentValue) * movePositionperStep*.935; // 5 pixels per step
      }
      else{
        positionChangeInPixels = (currentValue) * movePositionperStep ; // 5 pixels per step
      }
      
      prevValueRef.current = currentValue; // Update the previous value for the next event
      setValue(currentValue);
      const bewertungCount =document.getElementById('bewertungCount');
      bewertungCount.style.transform = `translateX(${positionChangeInPixels*0.975}px)`;
      const bewertungCountMobile = document.getElementById("bewertungCountMobile");
      bewertungCountMobile.style.transform = `translateX(${
        positionChangeInPixels *0.975
      }px)`;
      if(currentValue<=4){
        SetPrice((firstPrice*(parseInt(currentValue)+1)));
        
      }
      else if(currentValue<=9){
        SetPrice((secondPrice*(parseInt(currentValue)+1)));
      }
      else if(currentValue<=19){
        SetPrice((thirdPrice*(parseInt(currentValue)+1)));
      }
      else{
        SetPrice((fourthPrice*(parseInt(currentValue)+1)));
      }

      SetproBewertung(parseInt(currentValue)+1);
      SettotalProfit(profit[currentValue])
      
      handleChangeGlobalValue(parseInt(currentValue)+1)
      SetperPaket(parseInt(currentValue)+1)
    
      
    };


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const categories = [
    {
      key: "Google",
      icon: "/images/global/platform/google-icon.png",
      title: "Google",
    },
    {
      key: "Google Local Guide",
      icon: "/images/global/platform/local-guide-icon.png",
      title: "Google Local Guide",
    },
    {
      key: "Trustpilot",
      icon: "/images/global/platform/trustpilot-icon.png",
      title: "Trustpilot",
    },
    {
      key: "Arbeitgeber",
      icon: "/images/global/platform/arbeitgeber-icon.png",
      title: t.employer,
    },
    {
      key: "Google Loschen",
      icon: "/images/global/platform/loschen-icon.png",
      title: "Google",
    },
    {
      key: "Wunschplattform",
      icon: "/images/global/platform/wunsch-icon.png",
      title: t.wunschplattform,
    },
  ];

  
  useEffect(() => {
    function getPackages() {
      setPackages(t.data[0]);
    }
    getPackages();
  }, [selectedCategory, i18n, t]);

  

  return (
    <section className="preise" id="preise">
      <div className="px-8 py-12 mx-auto max-w-7xl lg:py-16">
        <div className="text-center">
          <h2>{t.price}</h2>
          <p className="pt-8 text-lg">{t.price_description}</p>
        </div>

        <Tab.Group>
          <Tab.List
            className="grid justify-center w-full grid-cols-6 mx-auto mt-10 rounded-full max-w-7xl z-full"
            style={{
              boxShadow: "3.599px 15.59px 92px 0px rgba(0, 0, 0, 0.14)",
            }}
          >
            {categories.map((category, index) => (
              <Tab
                selected={selectedCategory === category.key}
                key={index}
                className={({ selected }) =>
                  classNames(
                    "flex h-full w-full justify-center items-center gap-3 transition-all py-3 md:py-5 hover:bg-gradient-to-t hover:from-blue-100 hover:to-stone-100",
                    selected
                      ? "bg-gradient-to-t from-blue-100 to-stone-100"
                      : "",
                    index === 0 ? "rounded-l-full" : "",
                    index === 5 ? "rounded-r-full" : ""
                  )
                }
              >
                <>
                  <Image
                    src={category.icon}
                    alt={category.title}
                    width={28}
                    height={28}
                  />

                  <div className="flex-col items-start justify-start hidden lg:flex">
                    <p className="m-0 !text-xs font-bold !text-black leading-none">
                      {category.title}{" "}
                    </p>
                    {category.key === "Google Loschen" ? (
                      <span className="text-xs font-medium">{t.clear2}</span>
                    ) : (
                      <span className="text-xs font-medium">{t.reviews}</span>
                    )}
                  </div>
                </>
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels>
            {categories.map((category) => (
              <Tab.Panel
                key={category.key}
                className={`preise-category-${category.key}`}
                aria-labelledby={`preise-category-${category.key}`}
              >
                {category.key == "Google Loschen" ? (
                  <div className="flex flex-col justify-between h-full gap-12 mx-auto lg:mt-20 lg:items-end lg:flex-row md:items-center">
                     {packages[category.key]?.map((data, index) => ( 
                      <>
                      <div className="flex flex-col h-full gap-4 pt-8 lg:w-6/12 lg:gap-10 ">
                      <h4 className="text-4xl font-semibold">
                      {data.titleleft}
                      </h4>
                      <p>
                      {data.leftdesc1}
                      </p>
                      <p>
                      {data.leftdesc2}
                      </p>
                      <div >
                              <div
                                className="hidden px-10 py-8 bg-white rounded-3xl lg:block"
                                style={{
                                  boxShadow:
                                    "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                }}
                              >
                                <div>
                                  <label
                                    htmlFor="customRange2"
                                    className="inline-block mb-3 font-semibold"
                                  >
                                    {data.rangeTitle}
                                  </label>

                                  <div className="range-container relative !h-[14px]">
                                    <input
                                      type="range"
                                      className="transparent !h-[14px] !p-0 w-full cursor-pointer appearance-none rounded-lg border-transparent bg-neutral-300"
                                      min="0"
                                      max="29"
                                      step="1"
                                      value={value}
                                      id="customRange2"
                                      onInput={handleChange}
                                    />
                                    <div
                                      id="bewertungCountMobile"
                                      className="h-[30px] w-[30px] flex justify-center items-center range-step-indicator absolute left-0 top-[-1px] text-center text-white "
                                    >
                                      <span className="w-full h-full">
                                        {parseInt(value) + 1}
                                      </span>
                                    </div>
                                    <div className="absolute -translate-y-1 -left-4 top-1/2 ">
                                      <p className="!text-xs text-gray-400">
                                        1
                                      </p>
                                    </div>
                                    <div className="absolute -translate-y-1 -right-5 top-1/2 ">
                                      <p className="!text-xs text-gray-400">
                                        30
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <p className="!text-xs text-black font-medium text-end  pb-0 pt-8">
                                  {data.rangeText}{" "}
                                  <span className="p-1 px-2 font-semibold bg-green-300 rounded-full">
                                    {totalProfit} €
                                  </span>
                                </p>
                              </div>
                              <div className="lg:w-4/12 lg:hidden">
                                <div className="relative flex flex-col items-center justify-start max-w-md gap-4 px-8 py-10 mx-auto text-center text-white transition-all border preise-item-inner hover:cursor-pointer group bg-gradient-to-l from-blue-900 to-blue-600 md:w-full xl:px-14 rounded-3xl">
                                  <h4 className="font-bold uppercase">
                                    {data.title}
                                  </h4>
                                  <div className="flex items-start font-bold   preise-item-inner-price group-hover:text-white !text-white">
                                    <span className="text-4xl xl:text-6xl preise-item-inner-price-value">
                                      {" "}
                                      {price}
                                    </span>
                                    <span className="text-3xl preise-item-inner-price-currency">
                                      €
                                    </span>
                                  </div>
                                  <div className="flex items-center justify-center preise-item-inner-pro-bewertung ">
                                    <p className="preise-item-inner-pro-bewertung-value group-hover:text-white !text-white">
                                      <strong id="pro-bewertung">
                                        {proBewertung}
                                      </strong>{" "}
                                      {proBewertung == "1" &&
                                      data.name == "Bewertungen"
                                        ? "Bewertung "
                                        : data.name}
                                    </p>
                                  </div>
                                  <p className="preise-item-inner-total relative -top-2 group-hover:text-white !text-white">
                                    {data.garantie}
                                  </p>
                                  <div className="p-4 preise-item-inner-button">
                                    <a
                                    onClick={() => {
                                      setIsSelected(true)
                                    }}
                                      className="rounded-full font-medium py-3.5 px-6 sm:px-12  group-hover:bg-white group-hover:text-blue-700 text-sm group-hover:border-blue-700
                  !text-blue-700 bg-white"
                                      href="#kontakt"
                                    >
                                      {t.button}
                                    </a>
                                  </div>
                                  <div>
                                  {perPaket <= 5 ? (
                                      <p className="text-white !text-sm">
                                        <span className="text-base font-bold">
                                          {firstPrice}
                                          {setpriceLöschen(firstPrice)}
                                        </span>{" "}
                                        {data.proBwt}
                                      </p>
                                    ) : perPaket <= 10 ? (
                                      <p className="text-white !text-sm">
                                        <span className="text-base font-bold">
                                          {secondPrice}
                                          {setpriceLöschen(secondPrice)}
                                        </span>{" "}
                                        {data.proBwt}
                                      </p>
                                    ) 
                                    : perPaket <= 20 ? (
                                      <p className="text-white !text-sm">
                                        <span className="text-base font-bold">
                                          {thirdPrice}
                                          {setpriceLöschen(thirdPrice)}
                                        </span>{" "}
                                        {data.proBwt}
                                      </p>
                                    ) 
                                    : (
                                      <p className="text-white !text-sm">
                                        <span className="text-base font-bold">
                                          {fourthPrice}
                                          {setpriceLöschen(fourthPrice)}
                                        </span>{" "}
                                        {data.proBwt}
                                      </p>
                                    )}
                                  </div>

                                  <ul className="pb-2 space-y-3 text-sm">
                                    {data.features.map((feature, index) => (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: feature,
                                        }}
                                        className="group-hover:text-white text-xs sm:text-sm active:text-white text-left !text-white"
                                      ></div>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                    </div>
                   
                    <>
                              <div
                                className="px-10 py-8 bg-white lg:hidden rounded-3xl"
                                style={{
                                  boxShadow:
                                    "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                }}
                              >
                                <div >
                                  <label
                                    htmlFor="customRange2"
                                    className="inline-block mb-3 font-semibold"
                                  >
                                    {data.rangeTitle}
                                  </label>

                                  <div className="range-container relative !h-[14px]">
                                    <input
                                      type="range"
                                      className="transparent !h-[14px] !p-0 w-full cursor-pointer appearance-none rounded-lg border-transparent bg-neutral-300"
                                      min="0"
                                      max="29"
                                      step="1"
                                      value={value}
                                      id="customRange2"
                                      onInput={handleChange}
                                    />
                                    <div
                                      id="bewertungCount"
                                      className="h-[30px] w-[30px] flex justify-center items-center range-step-indicator absolute left-0 top-[-1px] text-center text-white "
                                    >
                                      <span className="w-full h-full">
                                        {parseInt(value) + 1}
                                      </span>
                                    </div>
                                    <div className="absolute -translate-y-1 -left-4 top-1/2 ">
                                      <p className="!text-xs text-gray-400">
                                        1
                                      </p>
                                    </div>
                                    <div className="absolute -translate-y-1 -right-5 top-1/2 ">
                                      <p className="!text-xs text-gray-400">
                                        30
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <p className="!text-xs text-black font-medium text-end  pb-0 pt-8">
                                  {data.rangeText}{" "}
                                  <span className="p-1 px-2 font-semibold bg-green-300 rounded-full">
                                    {totalProfit} €
                                  </span>
                                </p>
                              </div>
                              <div className="hidden lg:w-4/12 lg:block">
                                <div className="relative flex flex-col items-center justify-start max-w-md gap-4 px-8 py-10 mx-auto text-center text-white transition-all border preise-item-inner hover:cursor-pointer group bg-gradient-to-l from-blue-900 to-blue-600 md:w-full xl:px-14 rounded-3xl">
                                  <h4 className="font-bold uppercase">
                                    {data.title}
                                  </h4>
                                  <div className="flex items-start font-bold   preise-item-inner-price group-hover:text-white !text-white">
                                    <span className="text-4xl xl:text-6xl preise-item-inner-price-value">
                                      {" "}
                                      {price}
                                    </span>
                                    <span className="text-3xl preise-item-inner-price-currency">
                                      €
                                    </span>
                                  </div>
                                  <div className="flex items-center justify-center preise-item-inner-pro-bewertung ">
                                    <p className="preise-item-inner-pro-bewertung-value group-hover:text-white !text-white">
                                      <strong id="pro-bewertung">
                                        {proBewertung}
                                      </strong>{" "}
                                      {proBewertung == "1" &&
                                      data.name == "Bewertungen"
                                        ? "Bewertung "
                                        : data.name}
                                    </p>
                                  </div>
                                  <p className="preise-item-inner-total relative -top-2 group-hover:text-white !text-white">
                                    {data.garantie}
                                  </p>
                                  <div className="p-4 preise-item-inner-button">
                                    <a
                                    onClick={() => {
                                      setIsSelected(true)
                                    }}
                                      className="rounded-full font-medium py-3.5 px-6 sm:px-12  group-hover:bg-white group-hover:text-blue-700 text-sm group-hover:border-blue-700
                  !text-blue-700 bg-white"
                                      href="/#kontakt"
                                    >
                                      Jetzt Buchen
                                    </a>
                                  </div>
                                  <div>
                                    {perPaket <= 5 ? (
                                      <p className="text-white !text-sm">
                                        <span className="text-base font-bold">
                                          {firstPrice}
                                          {setpriceLöschen(firstPrice)}
                                        </span>{" "}
                                        {data.proBwt}
                                      </p>
                                    ) : perPaket <= 10 ? (
                                      <p className="text-white !text-sm">
                                        <span className="text-base font-bold">
                                          {secondPrice}
                                          {setpriceLöschen(secondPrice)}
                                        </span>{" "}
                                        {data.proBwt}
                                      </p>
                                    ) 
                                    : perPaket <= 20 ? (
                                      <p className="text-white !text-sm">
                                        <span className="text-base font-bold">
                                          {thirdPrice}
                                          {setpriceLöschen(thirdPrice)}
                                        </span>{" "}
                                        {data.proBwt}
                                      </p>
                                    ) 
                                    : (
                                      <p className="text-white !text-sm">
                                        <span className="text-base font-bold">
                                          {fourthPrice}
                                          {setpriceLöschen(fourthPrice)}
                                        </span>{" "}
                                        {data.proBwt}
                                      </p>
                                    )}
                                  </div>

                                  <ul className="pb-2 space-y-3 text-sm">
                                    {data.features.map((feature, index) => (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: feature,
                                        }}
                                        className="group-hover:text-white text-xs sm:text-sm active:text-white text-left !text-white"
                                      ></div>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              </>
                      </>
                    
                    ))}
                    
                  </div>
                ) : (
                  <Swiper
                    className="w-full mx-auto"
                    spaceBetween={50}
                    breakpoints={{
                      0: {
                        spaceBetween: 10,
                        slidesPerView: 1.1,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      1024: {
                        slidesPerView: 3,
                      },
                    }}
                  >
                    {packages[category.key]?.map((data, index) => (
                      <SwiperSlide
                        className="mx-auto preise-inner-content"
                        key={index}
                      >
                        <PriceCard
                          title={data.title}
                          price={
                            Math.round(
                              parseFloat(data.price.replace(",", ".")) * 100
                            ) / 100
                          }
                          value={data.value}
                          proBewertung={data.proBewertung}
                          total={
                            Math.round(
                              data.proBewertung *
                                parseFloat(data.price.replace(",", ".")) *
                                100
                            ) / 100
                          }
                          features={data.features}
                          active={
                            (index === 1 && !isMobile) || (isMobile && true)
                          }
                          loschungActive={category.key === "Google Loschen"}
                          loschungQuantity={
                            index === 0
                              ? data.loschungData2
                              : data.loschungData3
                          }
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </section>
  );
};

export default Price;
