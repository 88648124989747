import React from 'react';
import Image from "next/image";
import Link from 'next/link';
import { useTranslation } from '@/components/useTranslation';

const style = {
    wrapper: `mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8`,
    wrapperInner: `mx-auto text-center`,
    grid: `grid grid-cols-2 xl:grid-cols-4 gap-4 sm:gap-8 my-14`,
    itemInner: `bg-white hover:shadow-md transition-all hover:cursor-pointer py-6 px-6 sm:px-10 rounded-2xl border-gray-200 border h-full text-left flex flex-col items-center justify-center`,
    image1: `text-center mx-auto pb-6 hidden sm:block`,
    image2: `text-center mx-auto pb-3 sm:hidden`,
    miniTitle: `text-blue-700 text-sm font-bold`,
    title: `text-[16px] text-center md:!text-xl !p-0 font-semibold text-gray-900`,
}

const Platform = () => {

    const t = useTranslation();

    const platformData = [
        {
            title: t.employer,
            icon: '/images/global/platform/arbeitgeber-icon-big2.png',
        },
        {
            title: `Google ${t.clear2}`,
            icon: '/images/global/platform/loschen-big2.png',
        },
        {
            title: 'Trustpilot',
            icon: '/images/global/platform/trustpilot-icon-big.png',
        },
        {
            title: t.wunschplattform,
            icon: '/images/global/platform/wunsch-icon-big-2.png',
        },
    ]

    return (
        <section className='platform'>
            <div className={style.wrapper}>
                <div className={style.wrapperInner}>
                    <h2>{t.platform_title}</h2>
                    <div className={style.grid}>
                        {
                            platformData.map((platform, index) => {
                                return (
                                    <div className="platform-item" key={platform.title}>
                                        <div className={style.itemInner}>
                                            <Image src={platform.icon} alt={`${platform.title} Positive Kommentar`} className={style.image1} width={120} height={144} />
                                            <Image src={platform.icon} alt={`${platform.title} Positive Kommentar`} className={style.image2} width={80} height={80} />
                                            <h3 className={style.title}>{platform.title}</h3>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Platform;